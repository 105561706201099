import setCssVar from 'quasar/src/utils/set-css-var.js';
import { axios } from 'axios' // eslint-disable-line no-unused-vars
import router from '../router' // eslint-disable-line no-unused-vars

var hostForAPICalls = location.hostname;
hostForAPICalls = (location.hostname === 'reg.consentic.com' ? "https://app.consentic.com" : "https://cert.consentic.com");
const apiCall = require('axios').create({ withCredentials: true, 
                                          headers: { 'Cache-Control': 'no-cache' },
                                          baseURL: hostForAPICalls});

const getDefaultUserState = () => {
    return {
        Google_Client_ID:'',
        LinkedIn_Client_ID:'',
        TimeZone:"Australia/Brisbane",
        APIToken:'',
        APIHost:hostForAPICalls,
        trgXestroPracID:-1,
        getParameters: {},
        user:false,
        permissions:[],
        siteName:'',
        siteAbbr:'',
        WeatherLoc:'',
        ServerIP: '',
        sScreen:{},
        Lang: {
          label: 'English',
          value: 'en',
          flag: 'gb.svg'
        },
        LangSupp: [
          {
            label: 'English',
            value: 'en',
            flag: 'gb.svg'
          },
          {
            label: 'French',
            value: 'fr',
            flag: 'fr.svg'
          },
          {
            label: 'Spanish',
            value: 'sp',
            flag: 'mx.svg'
          },
        ],
        Theme:{
          'NavBG':'#FFFFFF',
          'NavHDR':'#425264', //(used to be SideBG)
          'NavSideBG':'#1E2A34', 
          'MenuBG':'#33566A', //(added a 6th colour as opacity doesn't work in state case)
          'ActiveText':'#26B1BA',
          'IdleText':'#A8B8C1',
          'TableHDR':'#7C8EA1',
          'Positive':'#114929',
          'Negative':'#501C28',
          'CGreen':'#16B1BA',
        },
        isDark:false,
        bBGColour:false,
        CurrColour:'grey',
        bBetaTest:false,
    }
}

const state = getDefaultUserState();

const actions = {
    setScreen({commit},sScreen) {commit('setScreen',sScreen);},
    toggleBeta({commit}) {commit('toggleBeta');},
    setBetaTest({commit},bVal) {commit('setBetaTest',bVal);},
    setXestroTrgID({commit},ID) {commit('setXestroTrgID',ID)},
    userLogin({commit},params) { commit('userLogin',params) },
    setLang({commit},sLang)    { commit('setLang',sLang) },
    setGetParameters({commit},params) {
      commit('setGetParameters',params);
    },
    setGoogleCID({commit},txtCID) {commit('setGoogleCID',txtCID)},
    setLinkedInCID({commit},txtCID) {commit('setLinkedInCID',txtCID)},
    setSiteName({commit},txtName) {commit('setSiteName',txtName)},
    setSiteAbbr({commit},txtAbbr) {commit('setSiteAbbr',txtAbbr)},
    SetBGColour({commit},bVal)          { commit('setBGColour',bVal) },
    toggleBGColour({commit}) {commit('toggleBGColour')},
    toggleDark({commit}) { commit('toggleDark'); },
    setTheme({commit},Colour) { commit('setTheme',Colour); },
    setDefTheme({commit}) {
        commit('setTheme','grey');
    },
    signIn({commit,dispatch},payload) {
      try {
          var username=(typeof payload.user=='undefined' ? '' : payload.user);
          var password=(typeof payload.pass=='undefined' ? '' : payload.pass);
          if(username !='' && password!='')
          {
          
              var AuthRec = new FormData();
              AuthRec.set('email', username);
              AuthRec.set('password', password);
              //console.warn("Test Host:",hostForAPICalls);

              apiCall.post(hostForAPICalls+'/api/login', AuthRec)
              .then(response => 
              {
                console.log("Response:",response);
                if(response?.data?.status === true) {
                  //console.log("Response:",response.data.permissions,response.data.user);
                  if(typeof response.data?.token!='undefined' && response.data?.user!='undefined')
                  {
                    commit('userLogin',response.data);
                  }

                }
                else {
                  dispatch('alerts/error', { MESG: 'Login Failed:'+response.data.ERROR} ,{ root: true });
                }
              })
              .catch(function (error) {
                  console.warn("Error caught from login service:",error);
                  if(typeof error?.response?.data?.message!='undefined')
                    dispatch('alerts/error', { MESG: error?.response?.data?.message } ,{ root: true });
                  else
                  dispatch('alerts/error', { MESG: 'Cannot connect to login service!', DETAIL1: 'Error Details: ' , DETAIL2:error, DETAIL3:'If this problem persists please contact support', LINK:'', LINKTEXT:'' } ,{ root: true });
              })
          }
      }
      catch {
          return false;
      }
    },
    signOut({commit},bClearHash) {
      apiCall.post(hostForAPICalls+'/core/logout/logout.php')
      .then(response => 
      {
        console.log("Response:",response);
        commit('userLogin',{});
        commit('userPerms',{});
        commit('setTZ',"Australia/Brisbane");
        commit('WeatherLoc','');
        commit('ServerIP','');
        //var host = window.location.host;
        clearInterval(window.dataUpdateInterval);
        clearInterval(window.beepInterval);
        delete window.dataUpdateInterval;
        delete window.beepInterval;
        if(bClearHash===true) {
          localStorage.removeItem('hash');
          localStorage.removeItem('date');
        }
        router.push({name:'login'});
        //If client side go back to login.php
        //
    });
  },
  setGraphicsView({state},payload) { // eslint-disable-line no-unused-vars
    console.warn("Setup Graphics View with:",payload);
    if(typeof payload=='undefined') payload={};
    if(typeof payload.Date!='undefined' && typeof payload.Hash!='undefined') {
      localStorage.setItem('date',payload.Date);
      localStorage.setItem('hash',payload.Hash);
    }
    router.push('/graphicsView');
  },
  /*switchToObserved({state,dispatch,commit,rootState},intPos)
    {
        //console.log("Where are my Observed?",rootState.tasks.OBSERVEDLIST);
        //console.log("%cSwitching to Observed:","color:red;font-size:25px;",intPos,rootState.tasks.OBSERVEDLIST[intPos]);
        dispatch('tasks/setObservedIndex',rootState.tasks.OBSERVEDLIST[intPos],{root:true});
        dispatch('setCurrObserved',rootState.tasks.OBSERVEDLIST[intPos].txtObserved);
        dispatch('setCurrObservedPos',intPos)
    },
    addAnonObserved({dispatch,commit})
    {
        commit("addAnonObserved");
    },
    addNewObserved({dispatch,commit},payload)
    {
        commit("insertNewObserved",payload);
    },
    setAvList({dispatch,commit},rAvatars){
        commit("SetAvatarList",rAvatars);
    },*/
};

const helperMethods = {
    componentToHex(c) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      },
      rgbToHex(r, g, b) {
        return "#" + this.componentToHex(r) + this.componentToHex(g) + this.componentToHex(b);
      },
      hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        } : null;
      },
};

const mutations = {
    setScreen(state,sScreen) {state.sScreen=sScreen},
    toggleBeta(state) {state.bBetaTest=!state.bBetaTest},
    setBetaTest(state,bVal){state.bBetaTest=bVal},
    setXestroTrgID(state,ID) {state.trgXestroPracID=ID},
    userLogin(state, sParams) {
      state.user=sParams.user;
      state.TZ=sParams.user.time_zone;
      if(typeof sParams?.token!='undefined') {
        var t=sParams.token.split('|');
        state.APIToken=t[1];
        localStorage.setItem('token',t[1]);
      }
      router.push('/dashboard');

    },
    setLang(state, sLang) {state.Lang=sLang},
    setGetParameters(state, params) {state.getParameters=params; console.warn("GET PARAMS:",state.getParameters);},
    setSiteName(state,txtName) {state.siteName=txtName;},
    setSiteAbbr(state,txtAbbr) {state.siteName=txtAbbr;},
    setGoogleCID: (state, txtCID) => { state.Google_Client_ID=txtCID;},
    setLinkedInCID: (state, txtCID) => { state.LinkedIn_Client_ID=txtCID;},
    setBGColour(state, bVal) {state.bBGColour=bVal;},
    toggleDark(state) {
      state.isDark=!state.isDark;
    },
    toggleBGColour(state) {state.bBGColour=!state.bBGColour},
    userPerms(state, rPerms){ state.permissions=rPerms; },
    setTZ(state, TZ){         state.TZ=TZ; },
    setAPIToken(state, token){         state.APIToken=token; },
    WeatherLoc(state, Loc){   state.WeatherLoc=Loc; },
    ServerIP(state, IP){      state.ServerIP=IP;  },
    setTheme(state, Colour){
        state.CurrColour=Colour;
        //console.log("State Colour set to:",state.CurrColour);
        if(state.isDark) {  //If we're dark, use the dark themes
          if(state.bBGColour) { //We're colouring in Background colours
            switch(Colour) {
              case "blue":
                state.Theme['NavBG']='#002B48';
                state.Theme['NavHDR']='#2C5683'; //(used to be SideBG)
                state.Theme['NavSideBG']='#082038'; 
                state.Theme['MenuBG']='#184A72'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#63AEF8';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#79A7D5';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#488BC8';
                state.Theme['Selected_row']='#316CA0'; 
                state.Theme['Current_row']='#194C74'; 
                state.Theme['Expanded_row']='#001330'; 
                state.Theme['Default_Primary_btn']='#63AEF8';
                state.Theme['Hover_Primary_btn']='#2689ED';
                state.Theme['Pressed_Primary_btn']='#185B9D';
                state.Theme['Default_Secondary_btn']='#4C7196';
                state.Theme['Hover_Secondary_btn']='#6794BA';
                state.Theme['Pressed_Secondary_btn']='#385B7D';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#63AEF8'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#CEE4FA';
                state.Theme['Pressed_Bulk_btn']='#ACD1F6';
              break;
              case "aqua":
                state.Theme['NavBG']='#2B3439';
                state.Theme['NavHDR']='#0B5351'; //(used to be SideBG)
                state.Theme['NavSideBG']='#011A1D'; 
                state.Theme['MenuBG']='#385D60'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#56BEBC';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#87A2B6';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#4A9999';
                state.Theme['Selected_row']='#40797B'; 
                state.Theme['Current_row']='#36575A'; 
                state.Theme['Expanded_row']='#011A1D'; 
                state.Theme['Default_Primary_btn']='#56BEBC';
                state.Theme['Hover_Primary_btn']='#00A39F';
                state.Theme['Pressed_Primary_btn']='#007673';
                state.Theme['Default_Secondary_btn']='#3E6263';
                state.Theme['Hover_Secondary_btn']='#508887';
                state.Theme['Pressed_Secondary_btn']='#204C4E';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#56BEBC'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#CDE2E3';
                state.Theme['Pressed_Bulk_btn']='#A6D0D0';
              break;
              case "purple":
                state.Theme['NavBG']='#0E0F2B';
                state.Theme['NavHDR']='#393E87'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#4D547A'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#9EA3E7';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#7C84A1';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#7F84BD';
                state.Theme['Selected_row']='#646B99'; 
                state.Theme['Current_row']='#474E72'; 
                state.Theme['Expanded_row']='#0E0F2B'; 
                state.Theme['Default_Primary_btn']='#9EA3E7';
                state.Theme['Hover_Primary_btn']='#757DE3';
                state.Theme['Pressed_Primary_btn']='#575DA7';
                state.Theme['Default_Secondary_btn']='#797AA1';
                state.Theme['Hover_Secondary_btn']='#999ACB';
                state.Theme['Pressed_Secondary_btn']='#53548A';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#9EA3E7'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#D5D7F4';
                state.Theme['Pressed_Bulk_btn']='#C2C5EF';
              break;
              /*case "pink":
                state.Theme['NavBG']='#301D2F';
                state.Theme['NavHDR']='#824C7D'; //(used to be SideBG)
                state.Theme['NavSideBG']='#20001F'; 
                state.Theme['MenuBG']='#5B3E59'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#DF80D4';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#BD97AE';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
              break;*/
              case "sage":
                state.Theme['NavBG']='#112726';
                state.Theme['NavHDR']='#1D4340'; //(used to be SideBG)
                state.Theme['NavSideBG']='#0C1213'; 
                state.Theme['MenuBG']='#2F5149'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#74B49B';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#96A89E';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#598E7B';
                state.Theme['Selected_row']='#426E60'; 
                state.Theme['Current_row']='#2A4A43'; 
                state.Theme['Expanded_row']='#0C1213'; 
                state.Theme['Default_Primary_btn']='#74B49B';
                state.Theme['Hover_Primary_btn']='#338275';
                state.Theme['Pressed_Primary_btn']='#517B6A';
                state.Theme['Default_Secondary_btn']='#31634F';
                state.Theme['Hover_Secondary_btn']='#57937B';
                state.Theme['Pressed_Secondary_btn']='#2C5847';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#74B49B'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#CBDED8';
                state.Theme['Pressed_Bulk_btn']='#A3C4BA';
              break;
              case "olive":
                state.Theme['NavBG']='#212921';
                state.Theme['NavHDR']='#2F422A'; //(used to be SideBG)
                state.Theme['NavSideBG']='#081208'; 
                state.Theme['MenuBG']='#3C4D34'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#7CA05F';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#869282';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#768D65';
                state.Theme['Selected_row']='#5E6E50'; 
                state.Theme['Current_row']='#3E4B38'; 
                state.Theme['Expanded_row']='#081208'; 
                state.Theme['Default_Primary_btn']='#95B27E';
                state.Theme['Hover_Primary_btn']='#809B4A';
                state.Theme['Pressed_Primary_btn']='#4A6039';
                state.Theme['Default_Secondary_btn']='#6B7961';
                state.Theme['Hover_Secondary_btn']='#97AA89';
                state.Theme['Pressed_Secondary_btn']='#495442';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#36A1CF'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#EEF0E1';
                state.Theme['Pressed_Bulk_btn']='#D0DCB8';
              break;
              case "grey":
              default:
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#425264'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#33566A'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#26B1BA';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#7C8EA1';
                state.Theme['Positive']='#114929';
                state.Theme['Negative']='#501C28';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#4690B1';
                state.Theme['Selected_row']='#3D7590'; 
                state.Theme['Current_row']='#355D73'; 
                state.Theme['Expanded_row']='#002433'; 
                state.Theme['Default_Primary_btn']='#51B0D9';
                state.Theme['Hover_Primary_btn']='#278FCC';
                state.Theme['Pressed_Primary_btn']='#006895';
                state.Theme['Default_Secondary_btn']='#216B88';
                state.Theme['Hover_Secondary_btn']='#5498B5';
                state.Theme['Pressed_Secondary_btn']='#004360';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#51B0D9'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#C4DFEB';
                state.Theme['Pressed_Bulk_btn']='#004360';
              break;
            }
          }
          else { //We're in dark theme and we are NOT colouring in backgrounds
            switch(Colour) {
              case "blue":
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#2C5683'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#184A72'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#63AEF8';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#79A7D5';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#488BC8';
                state.Theme['Selected_row']='#316CA0'; 
                state.Theme['Current_row']='#194C74'; 
                state.Theme['Expanded_row']='#002433'; 
                state.Theme['Default_Primary_btn']='#63AEF8';
                state.Theme['Hover_Primary_btn']='#2689ED';
                state.Theme['Pressed_Primary_btn']='#185B9D';
                state.Theme['Default_Secondary_btn']='#4C7196';
                state.Theme['Hover_Secondary_btn']='#6794BA';
                state.Theme['Pressed_Secondary_btn']='#385B7D';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#63AEF8'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#CEE4FA';
                state.Theme['Pressed_Bulk_btn']='#ACD1F6';
              break;
              case "aqua":
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#0B5351'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#385D60'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#56BEBC';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#87A2B6';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#4A9999';
                state.Theme['Selected_row']='#40797B'; 
                state.Theme['Current_row']='#36575A'; 
                state.Theme['Expanded_row']='#011A1D'; 
                state.Theme['Default_Primary_btn']='#56BEBC';
                state.Theme['Hover_Primary_btn']='#00A39F';
                state.Theme['Pressed_Primary_btn']='#007673';
                state.Theme['Default_Secondary_btn']='#3E6263';
                state.Theme['Hover_Secondary_btn']='#508887';
                state.Theme['Pressed_Secondary_btn']='#204C4E';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#56BEBC'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#CDE2E3';
                state.Theme['Pressed_Bulk_btn']='#A6D0D0';
              break;
              case "purple":
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#393E87'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#4D547A'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#9EA3E7';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#7C84A1';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#7F84BD';
                state.Theme['Selected_row']='#646B99'; 
                state.Theme['Current_row']='#474E72'; 
                state.Theme['Expanded_row']='#002433'; 
                state.Theme['Default_Primary_btn']='#9EA3E7';
                state.Theme['Hover_Primary_btn']='#757DE3';
                state.Theme['Pressed_Primary_btn']='#185B9D';
                state.Theme['Default_Secondary_btn']='#797AA1';
                state.Theme['Hover_Secondary_btn']='#999ACB';
                state.Theme['Pressed_Secondary_btn']='#53548A';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#9EA3E7'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#D5D7F4';
                state.Theme['Pressed_Bulk_btn']='#C2C5EF';
              break;
              /*case "pink":
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#824C7D'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#5B3E59'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#DF80D4';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#BD97AE';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
              break;*/
              case "sage":
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#1D4340'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#2F5149'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#74B49B';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#96A89E';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#598E7B';
                state.Theme['Selected_row']='#426E60'; 
                state.Theme['Current_row']='#2A4A43'; 
                state.Theme['Expanded_row']='#0C1213'; 
                state.Theme['Default_Primary_btn']='#74B49B';
                state.Theme['Hover_Primary_btn']='#338275';
                state.Theme['Pressed_Primary_btn']='#517B6A';
                state.Theme['Default_Secondary_btn']='#31634F';
                state.Theme['Hover_Secondary_btn']='#57937B';
                state.Theme['Pressed_Secondary_btn']='#2C5847';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#74B49B'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#CBDED8';
                state.Theme['Pressed_Bulk_btn']='#A3C4BA';
              break;
              case "olive":
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#2F422A'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#3C4D34'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#7CA05F';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#869282';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#768D65';
                state.Theme['Selected_row']='#5E6E50'; 
                state.Theme['Current_row']='#3E4B38'; 
                state.Theme['Expanded_row']='#1E2A34'; 
                state.Theme['Default_Primary_btn']='#95B27E';
                state.Theme['Hover_Primary_btn']='#809B4A';
                state.Theme['Pressed_Primary_btn']='#4A6039';
                state.Theme['Default_Secondary_btn']='#6B7961';
                state.Theme['Hover_Secondary_btn']='#97AA89';
                state.Theme['Pressed_Secondary_btn']='#495442';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#95B27E'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#EEF0E1';
                state.Theme['Pressed_Bulk_btn']='#D0DCB8';
              break;
              case "grey":
              default:
                state.Theme['NavBG']='#293947';
                state.Theme['NavHDR']='#425264'; //(used to be SideBG)
                state.Theme['NavSideBG']='#1E2A34'; 
                state.Theme['MenuBG']='#33566A'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#51B0D9';
                state.Theme['IdleText']='#A8B8C1';
                state.Theme['TableHDR']='#7C8EA1';
                state.Theme['Positive']='#114929';
                state.Theme['Negative']='#501C28';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#4690B1';
                state.Theme['Selected_row']='#3D7590'; 
                state.Theme['Current_row']='#355D73'; 
                state.Theme['Expanded_row']='#002433'; 
                state.Theme['Default_Primary_btn']='#51B0D9';
                state.Theme['Hover_Primary_btn']='#278FCC';
                state.Theme['Pressed_Primary_btn']='#006895';
                state.Theme['Default_Secondary_btn']='#216B88';
                state.Theme['Hover_Secondary_btn']='#5498B5';
                state.Theme['Pressed_Secondary_btn']='#004360';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#51B0D9'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#FFFFFF'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#C4DFEB';
                state.Theme['Pressed_Bulk_btn']='#004360';
              break;
            }
          }
        }
        else {  //Light themes
          if(state.bBGColour) { //  AND filling in backgound colours
            switch(Colour) {
              case "blue":
                state.Theme['NavBG']='#F0F7FF';
                state.Theme['NavHDR']='#649CCE'; //(used to be SideBG)
                state.Theme['NavSideBG']='#D9E6F2'; 
                state.Theme['MenuBG']='#B9CADD'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#085AAB';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#3776AF';
                state.Theme['Positive']='#3AE02C';
                state.Theme['Negative']='#911629';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#9BB6D5';
                state.Theme['Selected_row']='#7CA9D5'; 
                state.Theme['Current_row']='#​​B6D0EA'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#085AAB';
                state.Theme['Hover_Primary_btn']='#63AEF8';
                state.Theme['Pressed_Primary_btn']='#3F75AB';
                state.Theme['Default_Secondary_btn']='#9BB6D5';
                state.Theme['Hover_Secondary_btn']='#799EC8';
                state.Theme['Pressed_Secondary_btn']='#5D89BB';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#085AAB'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "aqua":
                state.Theme['NavBG']='#FAFEFE';
                state.Theme['NavHDR']='#90B8B9'; //(used to be SideBG)
                state.Theme['NavSideBG']='#EDFBFD'; 
                state.Theme['MenuBG']='#C2D1D1'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#057A7A';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#468486';
                state.Theme['Positive']='#509563';
                state.Theme['Negative']='#C22C3C';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#A2CECE';
                state.Theme['Selected_row']='#80BCBC'; 
                state.Theme['Current_row']='#​​BEDDDD'; 
                state.Theme['Expanded_row']='#E8FCFF'; 
                state.Theme['Default_Primary_btn']='#085AAB';
                state.Theme['Hover_Primary_btn']='#63AEF8';
                state.Theme['Pressed_Primary_btn']='#3F75AB';
                state.Theme['Default_Secondary_btn']='#9BB6D5';
                state.Theme['Hover_Secondary_btn']='#799EC8';
                state.Theme['Pressed_Secondary_btn']='#5D89BB';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#085AAB'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "purple":
                state.Theme['NavBG']='#ECEDF2';
                state.Theme['NavHDR']='#9297B9'; //(used to be SideBG)
                state.Theme['NavSideBG']='#DFE0EC'; 
                state.Theme['MenuBG']='#C8CADE'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#4D55A1';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#686E9D';
                state.Theme['Positive']='#3AE02C';
                state.Theme['Negative']='#911629';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#BDBAD9';
                state.Theme['Selected_row']='#9DA1C9'; 
                state.Theme['Current_row']='#​​C4C7DE'; 
                state.Theme['Expanded_row']='#ECEDFD'; 
                state.Theme['Default_Primary_btn']='#085AAB';
                state.Theme['Hover_Primary_btn']='#63AEF8';
                state.Theme['Pressed_Primary_btn']='#3F75AB';
                state.Theme['Default_Secondary_btn']='#9BB6D5';
                state.Theme['Hover_Secondary_btn']='#799EC8';
                state.Theme['Pressed_Secondary_btn']='#5D89BB';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#085AAB'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              /*case "pink":
                state.Theme['NavBG']='#E91E63';
                state.Theme['SideBG']='#F48FB1';
                state.Theme['MenuBG']='#F8BBD0';
                state.Theme['ActiveText']='#E91E63';
                state.Theme['IdleText']='#EEEEEE';
                state.Theme['ActiveBG']='#E91E63';
                state.Theme['Positive']='#3AE02C';
                state.Theme['Negative']='#911629';
              break;*/
              case "sage":
                state.Theme['NavBG']='#F8FCFC';
                state.Theme['NavHDR']='#9EBEB2';
                state.Theme['NavSideBG']='#E8F3EF';
                state.Theme['MenuBG']='#CAD2D0';
                state.Theme['ActiveText']='#457164'; 
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#4D8E75';
                state.Theme['Positive']='#2509563';
                state.Theme['Negative']='#C22C3C';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#A9BBAE';
                state.Theme['Selected_row']='#9FB7B0'; 
                state.Theme['Current_row']='#​​CBD9D6'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#457164';
                state.Theme['Hover_Primary_btn']='#74B49B';
                state.Theme['Pressed_Primary_btn']='#35534A';
                state.Theme['Default_Secondary_btn']='#A9BBAE';
                state.Theme['Hover_Secondary_btn']='#8DA594';
                state.Theme['Pressed_Secondary_btn']='#​​76937E';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#457164'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; 
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "olive":
                state.Theme['NavBG']='#F9FAF8';
                state.Theme['NavHDR']='#B8C09B'; //(used to be SideBG)
                state.Theme['NavSideBG']='#E9EDDC'; 
                state.Theme['MenuBG']='#CDD2CA'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#587D4F';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#96A08E';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#B4CCAC';
                state.Theme['Selected_row']='#A9BCA3'; 
                state.Theme['Current_row']='#​​D1DBCE'; 
                state.Theme['Expanded_row']='#F1F3EB'; 
                state.Theme['Default_Primary_btn']='#587D4F';
                state.Theme['Hover_Primary_btn']='#95B27E';
                state.Theme['Pressed_Primary_btn']='#4A6039';
                state.Theme['Default_Secondary_btn']='#B4CCAC';
                state.Theme['Hover_Secondary_btn']='#98B98D';
                state.Theme['Pressed_Secondary_btn']='#​​7FA871';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#587D4F'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "grey":
              default:
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#88939D';
                state.Theme['NavSideBG']='#E4E9EC';
                state.Theme['MenuBG']='#C6D7DF';
                state.Theme['ActiveText']='#36A1CF';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#425264';
                state.Theme['Positive']='#509563';
                state.Theme['Negative']='#C22C3C';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#B0D5E6';
                state.Theme['Selected_row']='#9BD0E7'; 
                state.Theme['Current_row']='#CDE7F3'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#36A1CF';
                state.Theme['Hover_Primary_btn']='#278FCC';
                state.Theme['Pressed_Primary_btn']='#006895';
                state.Theme['Default_Secondary_btn']='#B0D5E6';
                state.Theme['Hover_Secondary_btn']='#8BC1DA';
                state.Theme['Pressed_Secondary_btn']='#64ADCE';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#36A1CF'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
            }
          }
          else { //We're in Light mode and we are NOT filling in BG Colours
            switch(Colour) {
              case "blue":
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#649CCE'; //(used to be SideBG)
                state.Theme['NavSideBG']='#ECEDF2'; 
                state.Theme['MenuBG']='#B9CADD'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#085AAB';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#3776AF';
                state.Theme['Positive']='#3AE02C';
                state.Theme['Negative']='#911629';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#9BB6D5';
                state.Theme['Selected_row']='#7CA9D5'; 
                state.Theme['Current_row']='#​​B6D0EA'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#085AAB';
                state.Theme['Hover_Primary_btn']='#63AEF8';
                state.Theme['Pressed_Primary_btn']='#3F75AB';
                state.Theme['Default_Secondary_btn']='#9BB6D5';
                state.Theme['Hover_Secondary_btn']='#799EC8';
                state.Theme['Pressed_Secondary_btn']='#5D89BB';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#085AAB'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "aqua":
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#90B8B9'; //(used to be SideBG)
                state.Theme['NavSideBG']='#ECEDF2'; 
                state.Theme['MenuBG']='#C2D1D1'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#057A7A';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#468486';
                state.Theme['Positive']='#509563';
                state.Theme['Negative']='#C22C3C';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#A2CECE';
                state.Theme['Selected_row']='#80BCBC'; 
                state.Theme['Current_row']='#​​BEDDDD'; 
                state.Theme['Expanded_row']='#E8FCFF'; 
                state.Theme['Default_Primary_btn']='#085AAB';
                state.Theme['Hover_Primary_btn']='#63AEF8';
                state.Theme['Pressed_Primary_btn']='#3F75AB';
                state.Theme['Default_Secondary_btn']='#9BB6D5';
                state.Theme['Hover_Secondary_btn']='#799EC8';
                state.Theme['Pressed_Secondary_btn']='#5D89BB';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#085AAB'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "purple":
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#9297B9'; //(used to be SideBG)
                state.Theme['NavSideBG']='#E4E9EC'; 
                state.Theme['MenuBG']='#C8CADE'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#4D55A1';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#686E9D';
                state.Theme['Positive']='#3AE02C';
                state.Theme['Negative']='#911629';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#BDBAD9';
                state.Theme['Selected_row']='#9DA1C9'; 
                state.Theme['Current_row']='#​​C4C7DE'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#4D55A1';
                state.Theme['Hover_Primary_btn']='#868CC6';
                state.Theme['Pressed_Primary_btn']='#3F437C';
                state.Theme['Default_Secondary_btn']='#BDBAD9';
                state.Theme['Hover_Secondary_btn']='#9D99C7';
                state.Theme['Pressed_Secondary_btn']='#​​817BB7';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#4D55A1'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              /*case "pink":
                state.Theme['NavBG']='#E91E63';
                state.Theme['SideBG']='#F48FB1';
                state.Theme['MenuBG']='#F8BBD0';
                state.Theme['ActiveText']='#E91E63';
                state.Theme['IdleText']='#EEEEEE';
                state.Theme['ActiveBG']='#E91E63';
                state.Theme['Positive']='#3AE02C';
                state.Theme['Negative']='#911629';
              break;*/
              case "sage":
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#9EBEB2';
                state.Theme['NavSideBG']='#E4E9EC';
                state.Theme['MenuBG']='#CAD2D0';
                state.Theme['ActiveText']='#457164'; 
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#4D8E75';
                state.Theme['Bulk_Menu_row']='#A9BBAE';
                state.Theme['Selected_rowd']='#9FB7B0'; 
                state.Theme['Current_row']='#​​CBD9D6'; 
                state.Theme['Expanded_row']='#F3F5F6';
                state.Theme['Positive']='#2509563';
                state.Theme['Negative']='#C22C3C';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#A9BBAE';
                state.Theme['Selected_row']='#9FB7B0'; 
                state.Theme['Current_row']='#​​CBD9D6'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#457164';
                state.Theme['Hover_Primary_btn']='#74B49B';
                state.Theme['Pressed_Primary_btn']='#35534A';
                state.Theme['Default_Secondary_btn']='#A9BBAE';
                state.Theme['Hover_Secondary_btn']='#8DA594';
                state.Theme['Pressed_Secondary_btn']='#​​76937E';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#457164'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; 
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "olive":
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#B8C09B'; //(used to be SideBG)
                state.Theme['NavSideBG']='#E4E9EC'; 
                state.Theme['MenuBG']='#CDD2CA'; //(added a 6th colour as opacity doesn't work in state case)
                state.Theme['ActiveText']='#587D4F';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#96A08E';
                state.Theme['Positive']='#27803F';
                state.Theme['Negative']='#CC1E31';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#B4CCAC';
                state.Theme['Selected_row']='#A9BCA3'; 
                state.Theme['Current_row']='#​​D1DBCE'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#587D4F';
                state.Theme['Hover_Primary_btn']='#95B27E';
                state.Theme['Pressed_Primary_btn']='#4A6039';
                state.Theme['Default_Secondary_btn']='#B4CCAC';
                state.Theme['Hover_Secondary_btn']='#98B98D';
                state.Theme['Pressed_Secondary_btn']='#​​7FA871';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#587D4F'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
              case "grey":
              default:
                state.Theme['NavBG']='#FFFFFF';
                state.Theme['NavHDR']='#88939D';
                state.Theme['NavSideBG']='#E4E9EC';
                state.Theme['MenuBG']='#C6D7DF';
                state.Theme['ActiveText']='#26B1BA';
                state.Theme['IdleText']='#425264';
                state.Theme['TableHDR']='#717476';
                state.Theme['Positive']='#509563';
                state.Theme['Negative']='#C22C3C';
                //Additional Kit state items:
                state.Theme['Bulk_Menu_row']='#B0D5E6';
                state.Theme['Selected_row']='#9BD0E7'; 
                state.Theme['Current_row']='#CDE7F3'; 
                state.Theme['Expanded_row']='#F3F5F6'; 
                state.Theme['Default_Primary_btn']='#36A1CF';
                state.Theme['Hover_Primary_btn']='#278FCC';
                state.Theme['Pressed_Primary_btn']='#006895';
                state.Theme['Default_Secondary_btn']='#B0D5E6';
                state.Theme['Hover_Secondary_btn']='#8BC1DA';
                state.Theme['Pressed_Secondary_btn']='#64ADCE';
                state.Theme['Default_Hover_Pressed_Tertiary_btn']='#36A1CF'; /* this is the outline button which will be for print, csv, etc, feel free to use quasar's hover & pressed (ripple) effect*/  
                state.Theme['Default_Bulk_btn']='#293947'; /* font-colour: #3E7894; */
                state.Theme['Hover_Bulk_btn']='#3E576C';
                state.Theme['Pressed_Bulk_btn']='#121A21';
              break;
            }
          }
        }
        //Necessary for components that use bg-primary colour updates
        //Fill in RGB values so Opacity can be applied where required
        var x,RGB,ThemeKeys=Object.keys(state.Theme);
        for(x=0;x<ThemeKeys.length;x++) {
          RGB=helperMethods.hexToRgb(state.Theme[ThemeKeys[x]])
          if(RGB!=null)
          {
            state.Theme[ThemeKeys[x]+'_R']=RGB.r;
            state.Theme[ThemeKeys[x]+'_G']=RGB.g;
            state.Theme[ThemeKeys[x]+'_B']=RGB.b;
          }
        }
        setCssVar('primary',state.Theme['ActiveText']);
        setCssVar('positive',state.Theme['Positive']);
        setCssVar('negative',state.Theme['Negative']);
      },
      /*addGlobalTag (state,payload) {
        if(typeof state.sGlobalTags['ObsPos'+state.currentObservedPosition] == 'undefined')
            state.sGlobalTags['ObsPos'+state.currentObservedPosition]={'txtTags':[],'txtObserved':state.currentObserved};
        var x,keyIndex=-1;
        for(x=0;x<state.sGlobalTags['ObsPos'+state.currentObservedPosition].txtTags.length;x++)
        {
            if(payload.sTask.key==state.sGlobalTags['ObsPos'+state.currentObservedPosition].txtTags[x].key)
            {
                keyIndex=x;
                x=state.sGlobalTags['ObsPos'+state.currentObservedPosition].txtTags.length;
            }
        }
        if(keyIndex>=0)//overwrite with splice instead of append
            state.sGlobalTags['ObsPos'+state.currentObservedPosition]["txtTags"].splice(keyIndex,1,payload.sTask);
        else
            state.sGlobalTags['ObsPos'+state.currentObservedPosition]["txtTags"].push(payload.sTask);
        //console.log("New Global Tag:",state.sGlobalTags);
    },
    addAnonObserved (state,commit) {
        var sNewObs={};
        sNewObs.Created=new Date();
        var Rand1=Math.random()*100000;
        var Rand2=Math.random()*100000;
        sNewObs.ID=Math.round(-1*Rand1-Rand2);
        sNewObs.ObsDets={};
        sNewObs.PayRate="";
        sNewObs.PercentObs="0.00";
        sNewObs.RealName="Anon-"+Rand1+"_"+Rand2;
        sNewObs.TotalObs=0;
        sNewObs.dtmLastData="";
        sNewObs.txtAvImg="TBD";
        sNewObs.txtObserved="ANON-ANON_ANON";
        state.STUDYLIST[state.currentStudyIndex]['OBSERVEDLIST'].push(sNewObs);
    },*/
};

const getters = {
    getScreen: (state) => {return state.sScreen},
    getBetaTest: (state) => {return state.bBetaTest},
    getXestroPracID: (state) => {return state.trgXestroPracID},
    getAPIHost: (state) => {return state.APIHost},
    getGetParams: (state) => {return state.getParameters},
    getLang: (state) => { return state.Lang },
    getLanguages: (state) => { return state.LangSupp },
    getGoogleCID: (state) => { return state.Google_Client_ID},
    getLinkedInCID: (state) => { return state.LinkedIn_Client_ID},
    getSiteName: (state) => {return state.siteName},
    getSiteAbbr: (state) => {return state.siteAbbr},
    getThemeColour: (state) => {return state.CurrColour},
    getBGColour: (state) => { return state.bBGColour},
    getAuthUser (state) {return state.user},
    getAuthUserPrefs (state) {return (typeof state?.user?.prefs !='undefined' ? JSON.parse(state.user.prefs) : {}) },
    getTheme (state) {return state.Theme},
    getTZ (state) { return state.TimeZone},
    getAPIToken (state) { return state.APIToken},
    getUserInfo(state) { return state.user},
    getUserPerms(state) { return state.permissions},
    getWLoc(state) { return state.WeatherLoc},
    getDark(state) { return state.isDark},

    /*getAvList (state) { return state.AvImages;},
    getUserProfile (state) { return state.USERDETAILS; },
    getUserStudies (state) { return state.STUDYLIST; },
    getCurrentStudy (state) {return state.currentStudy},
    getCurrentStudyIndex (state) {return state.currentStudyIndex},
    getCurrentStudyID (state) {return state.currentStudyID},
    getCurrentStudyTitle (state) {return state.currentStudyTitle},
    getCurrentResearcher (state) {return state.currentResearcherName},
    getCurrentResearcherID (state) {return state.currentResearcherID},
    getCurrentCompanyID (state) {return state.currentCompanyID},
    getCurrentUserID (state) {return state.currentUserID},
    getCurrentStudyTags (state) {return state.currentStudy.txttags},
    getCurrentStudyLabelSet (state) {return state.currentStudy?.studyLabelSet?.studyLabels},
    getCurrentStudySubLabelSet (state) {return state.currentStudy?.studyLabelSet?.studySubLabels},*/
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};