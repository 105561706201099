import Notify from 'quasar/src/plugins/Notify.js';;

const state = {
    //type: null,
    //message: null,
    //code: null
};

const actions = {
    success({ commit }, message) {
        commit('success', message);
    },
    error({ commit }, message) {
        commit('error', message);
    },
    clear({ commit }, message) {
        commit('success', message);
    },
    Notify({commit, getters, rootGetters},sMess) { //eslint-disable-line no-unused-vars
        if(typeof sMess=='string') { 
            var t=sMess;
            sMess={};
            sMess.txtMsg=t;
        }
        if(typeof sMess.txtMsg=='undefined') return false;
        if(typeof sMess.txtType=='undefined') sMess.txtType='positive';
        const sScreen = rootGetters['users/getScreen']; // Access using rootGetters
        if(typeof sMess.txtPos=='undefined') sMess.txtPos=(sScreen.xs || sScreen.sm ? 'center' : 'bottom');
        if(typeof sMess.txtPos=='undefined') sMess.txtPos='bottom';
        if(typeof sMess.timeout=='undefined') sMess.timeout=2000;
        Notify.create({ type:sMess.txtType, timeout:sMess.timeout, message: sMess.txtMsg, position: sMess.txtPos,html: true });
    },    
    Alert({commit, getters, rootGetters},sMess) { //eslint-disable-line no-unused-vars
        if(typeof sMess=='string') { 
            var t=sMess;
            sMess={};
            sMess.txtMsg=t;
        }
        if(typeof sMess.txtMsg=='undefined') return false;
        if(typeof sMess.txtType=='undefined') sMess.txtType='negative';
        const sScreen = rootGetters['users/getScreen']; // Access using rootGetters
        if(typeof sMess.txtPos=='undefined') sMess.txtPos=(sScreen.xs || sScreen.sm ? 'center' : 'bottom');
        if(typeof sMess.timeout=='undefined') sMess.timeout=2000;
        Notify.create({ type:sMess.txtType, timeout:sMess.timeout, message: sMess.txtMsg, position: sMess.txtPos,html: true });
    },
};

const mutations = {
    success(state, message) {
        //state.type = 'alert-success';
        //state.message = message;
        if(typeof message != 'undefined' && message!='')
            Notify.create({ type:'positive', timeout:2000, message: message });
    },
    error(state, errorDetails) {
        console.log(errorDetails);
        state.type = 'alert-danger';
        console.log(errorDetails.MESG);
        /*state.message = errorDetails.MESG;
        if ( (typeof errorDetails.DETAIL1 !== 'undefined') && errorDetails.DETAIL1 !== '' && errorDetails.DETAIL1 !== 'undefined' ) {
            state.message += '<br>'+errorDetails.DETAIL1
        }
        if ( (typeof errorDetails.DETAIL2 !== 'undefined') && errorDetails.DETAIL2 !== '' && errorDetails.DETAIL2 !== 'undefined' ) {
            state.message += '<br>'+errorDetails.DETAIL2
        }
        if ( (typeof errorDetails.DETAIL3 !== 'undefined') && errorDetails.DETAIL3 !== '' && errorDetails.DETAIL3 !== 'undefined' ) {
            state.message += '<br>'+errorDetails.DETAIL3
        }
        state.code= errorDetails.CODE;*/
        var MyMessage=errorDetails.MESG;
        if ( (typeof errorDetails.DETAIL1 !== 'undefined') && errorDetails.DETAIL1 !== '' && errorDetails.DETAIL1 !== 'undefined' ) 
            MyMessage += ' -- '+errorDetails.DETAIL1
        if ( (typeof errorDetails.DETAIL2 !== 'undefined') && errorDetails.DETAIL2 !== '' && errorDetails.DETAIL2 !== 'undefined' ) 
            MyMessage += ' -- '+errorDetails.DETAIL2
        if ( (typeof errorDetails.DETAIL3 !== 'undefined') && errorDetails.DETAIL3 !== '' && errorDetails.DETAIL3 !== 'undefined' ) 
            MyMessage += ' -- '+errorDetails.DETAIL3
        Notify.create({
            type:'negative',
            timeout:2000,
            message: MyMessage
        });
    },
    clear(state) {
        state.type = null;
        state.message = null;
        state.code= null;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations
};